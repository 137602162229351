import React from "react";
import { HomeOutline, CalendarOutline } from "react-ionicons";
import { Link } from "react-router-dom";

const admin_menu_items = [
    {
        "name": "home",
        "icon": <HomeOutline/>,
        "link": "/admin/"
    },
    {
        "name": "calendar",
        "icon": <CalendarOutline/>,
        "link": "/admin/calendar/"
    }
]




export const AdminMenu = () => {
    return (
        <>
        <div className="admin-menu-div">
            <ul>
                {admin_menu_items.map((value) => {
                    return (
                        <li>
                            <Link to={value.link}>
                            {value.icon}
                            </Link>
                        </li>
                    )
                })}
               
            </ul>
        </div>
        </>
    )
}