import React from "react";
import '../Style/Components/ChartComponent.css';

class PieChart extends React.Component {
    constructor(props) {
        super(props);
    }
    render = () => {
        return (
            <span className="pie-chart" style={{backgroundImage: `radial-gradient(#FFF 60%, transparent 31%), conic-gradient(rgba(23, 255, 116, 0.75) 0% ${(this.props.value / this.props.parpose) * 100}%, #d9d9d9 0% 100%)`}}>
                {this.props.value}/{this.props.parpose}
            </span>
        )
    }
}

export default PieChart