import React from "react";
import { CloseOutline } from "react-ionicons";
import { can_access, json_request, renew_token } from "../Module/request";
import { routing } from "../Config/config";
import '../Style/Components/Modal.css'
class RegistrationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flat_value: 0,
            curve_value: 0,
            flat_date: 0,
            curve_date: 0
        }
    }

    onCommit = async () => {

    
        const is_access = await can_access()
        if (!is_access) {
             const refresh = await renew_token()
        }
        const set_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'set_parpose',
            user_id: this.props.user_id,
            data:[
                {type: 0, value:this.state.curve_value, date: this.state.curve_date},
                {type: 1, value:this.state.flat_value, date: this.state.flat_date}
            ] 
        })
        if(set_data.response) {
            window.alert("登録しました")
            this.props.onClose()
            window.location.reload()
        } else {
            window.alert("エラーが発生しました")
        }
    }
    render = () => {
        return (
            
            <>
            {this.props.is_open? (
            <div className="modal-background">
                <div className="registration-modal">
                    <div className="modal-header">
                        <buttton className="close-button" onClick={() => {this.props.onClose();}}>
                            <CloseOutline/>
                        </buttton>
                    </div>
                    <div className="modal-body">
                        <span className="title">目標値を登録</span>
                        <div className="first-section">
                            <span>曲</span>
                            <input type="number"
                             value={this.state.curve_value} 
                             onChange={(e) => {this.setState({curve_value: e.target.value})}}/>
                            <input type="month"
                            value={this.state.curve_date}
                            onChange={(e) => {this.setState({curve_date: e.target.value})}}
                            />
                        </div>
                        <div className="second-section">
                            <span>平</span>
                            <input type="number"
                            value={this.state.flat_value}
                            onChange={(e) => {this.setState({flat_value: e.target.value})}}
                             />
                            <input type="month"
                            value={this.state.flat_date}
                            onChange={(e) => {this.setState({flat_date: e.target.value})}}
                            />
                        </div>
                        
                        <button onClick={() => {this.onCommit()}}>
                            登録する
                        </button>
                    </div>
                </div>
            </div>
            ):(<></>)}
           
            </>
        )
    }
}

export default RegistrationModal;



