import React from "react";
import {Routes, Route} from "react-router-dom";
import HomeView from "../../../Page/App/Home";
import HistoryView from "../../../Page/App/History";
import { has_account } from "../../../Module/request";


class MobileAppView extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount = async () => {
        if(! await has_account())window.location.href = "/login/"
    }
    render = () => {
        return (
        <>
        <div className="mobile-app-view">
            <Routes>
                <Route exact path={"/"} element={<HomeView/>}/>
                <Route exact path={"history/"} element={<HistoryView/>}/>
            </Routes>
        </div>
        </>
        )
    }
}

export default MobileAppView;