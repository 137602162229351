import React from "react";
import { CloseOutline } from "react-ionicons";
import {json_request, can_access, renew_token } from "../Module/request"
import  {routing} from '../Config/config'
class SetupModal extends React.Component {
    constructor(props) {
        super(props);
    }
    
    onCommit = async (type) => {
        const is_access = await can_access()
        if (!is_access) {
             const refresh = await renew_token()
        }
        const set_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'setup',
            type: type,
        })
        if(set_data.response) {
            window.alert("セットアップが完了しました")
            this.props.onClose()
            window.location.reload()
        }

    }
    render = () => {
        return (
            <>
            {this.props.is_open? 
            (<>
            <div className="modal-background">
                <div className="setup-modal">
                    <div className="modal-header">
                        <button className="close-button" onClick={() => {this.props.onClose()}}>
                            <CloseOutline/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>今日生産する方を選んでください</span>
                        <div className="button-section">
                            <button onClick={() => {this.onCommit(0)}}>曲</button>
                            <button onClick={() => {this.onCommit(1)}}>平</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            ) :(<></>)}
            </>
        )
    }
}
export default SetupModal;
