import React from "react";
import {CaretForward} from "react-ionicons"
import PieChart from "../../Components/ChartComponent";
import '../../Style/App/Home.css'
import { Link } from "react-router-dom";
import { routing } from "../../Config/config";
import { can_access, json_request, renew_token } from "../../Module/request";
import { CloseOutline } from "react-ionicons";

class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pie_chart:<></>,
            value: 0,
            parpose: 0,
            is_open_modal: false,
            today_count: 0
        }
    }

    onCommit = async () => {
        const is_access = await can_access()
        if (!is_access) {const refresh = await renew_token()}
        const today_data = await json_request(`${routing.api.host}/noadminuser/`, {
            command: 'registration_data',
            value: this.state.today_count
        })

        if(today_data.response){
            window.alert("登録しました")
            const today_data = await json_request(`${routing.api.host}/noadminuser/`, {
                command: 'get_today_data'
            })
       
            this.setState({
                value: today_data.params.value,
                parpose: today_data.params.parpose,
                is_open_modal: false
            })
        } else {
            window.alert("管理者が今日のセットアップを行なっていません")
        }
    }


    componentDidMount = async () => {
        const is_access = await can_access()
        if (!is_access) { const refresh = await renew_token()}
        const today_data = await json_request(`${routing.api.host}/noadminuser/`, {
            command: 'get_today_data'
        })
        const type = today_data.params.type
        const parpose = today_data.params.parpose
        const value = today_data.params.value
        this.setState({value: value, parpose: parpose})
        if(type === 3){
            window.alert("管理者がセットアップを行なっていません")
        } else if (!value) {
            this.setState({is_open_modal: true})
        }
    }
    
    render = () => {
        return (
            <>
            <div className="home-view">
                <div className="top">
                    <span className="title">今日の達成度</span>
                    <div className="graph">
                    <PieChart value={this.state.value} parpose={this.state.parpose}/>
                    </div>
                </div>
                <div className="menu">
                    <ul>
                        <li>
                            <Link to={"/app/history/"}>
                                <span className="button-title">これまでの達成度</span>
                                <CaretForward/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            {this.state.is_open_modal? (
            <>
            <div className="modal-background">
                <div className="prompt-modal">
                    <div className="modal-header">
                        <button>
                            <CloseOutline/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="input-section">
                            <input type="number" value={this.state.today_count} onChange={(e) => {this.setState({today_count: e.target.value})}}/>
                            <button onClick={() => {this.onCommit()}}>登録する</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            ):(<></>)}
            </>
        )
    }
}
export default HomeView;