import logo from './logo.svg';
import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LoginView from './View/App/Auth/Login';
import MobileAppView from './View/App/App/App';
import AdminAppView from './View/Admin/App/App';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path={"admin/*"} element={<AdminAppView/>}/>
          <Route exact path={"admin/login/"}/>
          <Route exact path={"login/"} element={<LoginView />}/>
          <Route exact path={"app/*"} element={<MobileAppView />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
