import React from "react";
import '../../Style/App/Home.css'
import RegistrationModal from "../../Components/Registration";
import { json_request, renew_token, can_access } from "../../Module/request";
import { routing } from "../../Config/config";


class AdminHomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_open_registration: false,
            today_data: [],
            selected_user_id: 0
        }
    }

    componentDidMount = async () => {
        const is_access = await can_access()
        if (!is_access) {const refresh = await renew_token()}
        const today_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'get_today_data'
        }) 
        this.setState({today_data: today_data.params})
      
        
    }
    render = () => {
        return (
        <>
        <div className="admin-home-view">
            <table>
                <thead>
                    <tr>
                        <td>最終更新月</td>
                        <td>名前</td>
                        <td>目標枚数</td>
                        <td>今日の達成枚数</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.today_data.map((value) => {
                        return (
                            <tr>
                                <td>{value.update_at}</td>
                                <td>{value.name}</td>
                                <td>{value.parpose}</td>
                                <td>{value.value}</td>
                                <td>
                                    <button className="registration-button" onClick={() => {
                                        this.setState({is_open_registration: !this.state.is_open_registration, selected_user_id: value.id})}
                                        }>
                                         目標枚数を登録する
                                    </button>
                                </td>
                                <td>
                                    <button className="user-page-button" onClick={() => {
                                        window.location.href = `/admin/user/${value.id}`;
                                    }}>
                                       ユーザーページへ行く
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </div>
        <RegistrationModal 
        is_open={this.state.is_open_registration} 
        onClose={() => {this.setState({is_open_registration: false})}}
        user_id={this.state.selected_user_id}/>
        </>
        )
    }
}

export default AdminHomeView;