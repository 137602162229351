import React from "react";
import { Line } from 'react-chartjs-2';
import { json_request, renew_token, can_access } from "../../Module/request";
import { routing } from "../../Config/config";
import { ChevronBackOutline } from 'react-ionicons'
import '../../Style/App/History.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import { Link } from "react-router-dom";

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '達成推移',
      },
    },
  };
const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

class HistoryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            line_chart: <></>,
            history_data: []
        }
    }

    componentDidMount = async () => {
        const is_access = await can_access()
        if (!is_access) {const refresh = await renew_token()}
        
        const history_data = await json_request(`${routing.api.host}/noadminuser/`, {
            command: 'get_history',
        }) 
        
        const _graph_data = {
            labels,
            datasets: [{
                label: "達成枚数の推移",
                data: history_data.graph_data,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }]
        }
       

        this.setState({
            line_chart: <Line data={_graph_data} options={options}/>,
            history_data: history_data.table_data
        })
    }

    render = () => {
        return (
            <>
            <div className="history-view">
                <div className="history-header">
                    <Link to={"/app/"}>
                    <ChevronBackOutline/>
                    </Link>
                </div>
                <div className="history-graph">
                    {this.state.line_chart}
                </div>
                <div className="history-table">
                    <ul>
                        {this.state.history_data.map((value) => {
                            return (
                                <li>
                                    <div className="history-div">
                                        <span>{value.time_stamp}</span>
                                        <div className="visual">
                                            <span> {value.value}/{value.parpose}</span>
                                            <span className="gage-span">
                                                <div className="gage">
                                                    <span 
                                                    className="bar"
                                                    style={{width: `${(value.value / value.parpose) * 100}%`}}
                                                    ></span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            </>
        )
    }
}

export default HistoryView;