import React from "react"; 
import { Line } from 'react-chartjs-2';
import { json_request, renew_token, can_access } from "../../Module/request";
import { routing } from "../../Config/config";
import '../../Style/App/User.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '達成推移',
      },
    },
  };
const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]


class AdminUserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            line_chart: <></>,
            user_data :{}
        }
    }
    componentDidMount = async () => {
        const is_access = await can_access()
        if (!is_access) {const refresh = await renew_token()}

        const user_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'get_user_data',
            user_id: window.location.href.split("/")[5]
        })
        const graph_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'get_graph',
            user_id: window.location.href.split("/")[5]
        })
        
        const _graph_data = {
            labels,
            datasets: [{
                label: "達成枚数の推移",
                data: graph_data.params,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }]
        }

        this.setState({
            line_chart: <Line data={_graph_data} options={options}/>,
            user_data: user_data.params
        })
    }
    render = () => {
        return (
            <>
            <div className="admin-user-view">
                <div className="graph-section">
                    {this.state.line_chart}
                </div>
                <div className="status-section">
                    <span className="section-title">基本情報</span>
                    <div className="user-name">
                        <span className="title">名前</span>
                        <span className="content">{this.state.user_data.username}</span>
                    </div>
                    <div className="registration_date">
                        <span className="title">登録日</span>
                        <span className="content">{this.state.user_data.created_at}</span>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }
}

export default AdminUserView