import React from "react";
import { AdminMenu } from "../../../Components/AdminMenu";
import {Routes, Route} from 'react-router-dom';
import '../../../Style/App/Admin.css';
import AdminHomeView from "../../../Page/Admin/Home";
import AdminCalendarView from "../../../Page/Admin/Calendar";
import AdminUserView from "../../../Page/Admin/User"
import MemberRegistrationModal from "../../../Components/Member";
import SetupModal from "../../../Components/Setup";

import {can_access, json_request, renew_token} from "../../../Module/request"
import { routing } from "../../../Config/config";
class AdminAppView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_open_memberregistratoion: false,
            is_open_setup: false
        }
    }

    componentDidMount = async () => {
        const is_access = await can_access()
        if (!is_access) {
             const refresh = await renew_token()
        }
        const set_data = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'need_setup?',
        })
        if(set_data.response){
            this.setState({is_open_setup: true})
        }
    }
    render = () => {
        return (
            <>
            <div className="admin-view">
                <header>
                    <span className="title">U-BASE/admin</span>
                    <button onClick={() => {this.setState({is_open_memberregistratoion: true})}}>
                    メンバーを追加する
                </button>
                </header>
                
                <div className="admin-body">
                    <div className="admin-menu">
                        <AdminMenu/>
                    </div>
                    <div className="admin-content">
                        <Routes>
                            <Route exact path={"/"} element={<AdminHomeView/>}/>
                            <Route exact path={"/calendar/"} element={<AdminCalendarView/>}/>
                            <Route exact path={"/user/*"} element={<AdminUserView/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
            <MemberRegistrationModal is_open={this.state.is_open_memberregistratoion} onClose={() => {this.setState({is_open_memberregistratoion: false})}}/>
            <SetupModal is_open={this.state.is_open_setup} onClose={() => {this.setState({is_open_setup: false})}}/>
            </>
        )
    }
}

export default AdminAppView;