import React from 'react';
import {EyeOutline, EyeOffOutline} from  'react-ionicons'
import { routing } from '../../../Config/config';
import { login } from '../../../Module/request';
import '../../../Style/Auth/Login.css'
class LoginView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            is_hidden: true,
            username: "",
            password: "",
        }
    }

    onCommit = async () => {
        const login_data = {
            username: this.state.username,
            password: this.state.password,
        }
        const response = await login(login_data)
        if(response.response !== 200){
            window.alert("Login failed")
        } else {
            if(response.is_admin){
                window.location.href = `/admin/`
            } else {
                window.location.href = `/app/`
            }
        }
    }
    render = () => {
        return (
            <>
            <div className="login-view">
                <div className="login-body">
                    <span className="login-title">U-BASEにログイン</span>
                    <div className="login-widget">
                        <span className="widget-title">ユーザーID</span>
                        <div className="widget-item">
                             <input type="email"
                             value={this.state.username}
                             onChange={(e) => this.setState({username: e.target.value})}
                             ></input>
                        </div>
                        <span className='widget-title'>パスワード</span>
                        <div className="widget-item">
                            <input 
                            type={this.state.is_hidden? "password": "text"} 
                            value={this.state.password} 
                            onChange={(e) => {this.setState({password: e.target.value})}}></input>

                            <button onClick={() => {this.setState({is_hidden: !this.state.is_hidden})}}>
                                {this.state.is_hidden? (<EyeOutline/>):(<EyeOffOutline/>)}
                            </button>
                        </div>
                        <button className="login-button" onClick={() => {this.onCommit()}}>ログイン</button>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default LoginView;