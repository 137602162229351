import React from 'react';
import { routing } from '../../Config/config';
import { can_access, json_request, renew_token } from '../../Module/request';
import '../../Style/App/Calendar.css'



class AdminCalendarView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            username_array: [],
            date_array: [],
            daily_value: {}
        }
    }

    componentDidMount = async () => {
        const username = []
        const daily_data = []
        const check_array = []

        const is_access = await can_access()
        if (!is_access) {
             const refresh = await renew_token()
        }
        const response = await json_request(`${routing.api.host}/adminuser/`, {
            command: 'get_monthly_data'
        }) 
        const parent_data = response.params
        for (let i = 0; i < parent_data.length; i++) {
            if(!daily_data[parent_data[i]["time_stamp"]]){
                daily_data[parent_data[i]["time_stamp"]] = [parent_data[i]["value"]]
            } else {
                daily_data[parent_data[i]["time_stamp"]].push(parent_data[i]["value"])
            }

            if(!(check_array.includes(parent_data[i]["user_id"]))){
                check_array.push(parent_data[i]["user_id"])
                username.push(parent_data[i]["name"])
            }
        }
        this.setState({username_array: username, daily_value: daily_data, date_array: Object.keys(daily_data)})
    }
    render = () => {
        return (
            <>
            <div className="admin-calendar-view">
                <table>
                    
                    <tbody>
                    <tr>
                        <td>日付/名前</td>
                        {this.state.username_array.map((name) => {
                            return(
                            <td>{name}</td>
                            )})}
                    </tr>
                    {this.state.date_array.map((date) => {
                        return (
                        <tr>
                            <td>{date}</td>
                            {this.state.daily_value[date].map((data) => {
                                return (
                                <td>{data}</td>
                                )
                                })}
                         </tr>
                         )
                    })}
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default AdminCalendarView;