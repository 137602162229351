import { routing } from "../Config/config";


export const get_csrf_token  = async () => {
    const response = await fetch(`${routing.api.host}/get_csrf_token/`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
    })
    const result = await response.json()
    return result.token
}



export const json_request = async (url, data) => {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify(data),
        credentials: "include"
    })
    return await response.json();
}

export const login = async (value) => {
    const response = await fetch(`${routing.api.host}/auth/login/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(value)
    })
   const result = await response.json();
   if(result.non_field_errors){
    return 403
} else {
    let set_cookie = await fetch(`${routing.api.host}/verification/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            command: "login",
            access_token: result.access_token,
            refresh_token: result.refresh_token
        }),
        credentials: "include"
    })
    let cookie_data = await set_cookie.json()
    if (await cookie_data.response !== 200){
        return 403
    } else {
        return {response: 200, is_admin: cookie_data.is_admin}
    }
}

}




export const signup = async (value) => {
    if(value.username ==="" || value.email ==="" || value.password1 === "", value.password2 === ""){
        return {response: 403, "message": "全ての項目を入力してください"}
    }
    if (value.password1 !== value.password2){
        return {response: 403, "message": "パスワードが一致しません"}
    }
    let response = await fetch(`${routing.api.host}/auth/registration/`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
           username: value.username,
           email: value.email,
           password1: value.password1,
           password2: value.password2
        })
    })
    let result = await response.json()
    if (result.email){
        if(result.email[0].includes("already")){
            return {response: 403, "message": "そのメールアドレスはすでに使用されています"}
        } else if(result.email[0].includes("valid")){
            return {response: 403, "message": "正しいメールアドレスを入力してください"}
        }
    }
    else if(result.username){
        return {resonse: 403, "message": "そのIDはすでに使用されています"}
    }
    else if(result.password1){
        if(result.password1[0].includes("short")){
            return {response: 403, "message": "パスワードが短すぎます。最低8文字以上で登録してください。"}
        }
    } else if(result.non_field_errors){
        return {response: 403, "message": "パスワードがユーザネームと似ているので登録できません."}
    }else{
        const set_name = await fetch(`${routing.api.host}/verification/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": await get_csrf_token()
            },
            body: JSON.stringify({
                "command": "registration",
                "access_token": result.access_token,
                "name": value.name
            })
        })
        const response = await set_name.json()
    
        if (response.response) {
            return {response: 200, "message": "アカウントを作成しました。", token: result.access_token}
        } else {
            return {response: 403}
        }
    }
}


export const can_access = async () => {
    const is_access_request = await fetch(`${routing.api.host}/verification/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            "command": "can_access?",
        }),
        credentials: "include"
    })
    const response = await is_access_request.json()
    return response.response
}

export const renew_token = async ()  => {
    const renew_token_request = await fetch(`${routing.api.host}/verification/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            command: "get_refresh_token"
        }),
        credentials: "include"
    })

    const refresh_token = await renew_token_request.json()
    
    const renew_request = await fetch(`${routing.api.host}/auth/token/refresh/`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            refresh: refresh_token.refresh_token
        })
    })
    const renew_token_sets = await renew_request.json()
    
    const set_token_request = await fetch(`${routing.api.host}/verification/`, {
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            command: "set_renew_token",
            access_token: renew_token_sets.access,
            refresh_token: renew_token_sets.refresh
        }),
        credentials: "include"
    })
    return await set_token_request.json()
}

export const has_account = async () => {
    const is_access_request = await fetch(`${routing.api.host}/verification/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": await get_csrf_token()
        },
        body: JSON.stringify({
            "command": "has_account?",
        }),
        credentials: "include"
    })
    const response = await is_access_request.json()
    return response.response
}