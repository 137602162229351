import React from "react";
import { CloseOutline } from "react-ionicons";
import { signup } from "../Module/request";
import '../Style/Components/MemberRegistration.css'

class MemberRegistrationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            username: "",
            email: "",
            password1: "",
            password2: "",
        }
    }

    onCommit = async () => {
        const res = await signup(this.state)
        if(res.response !== 200){
            window.alert(res.message)
        } else {
            window.alert(res.message)
            this.props.onClose()
        }
    }
     render = () => {
         return (
             <>
             {this.props.is_open ? (
             <>
             <div className="modal-background">
                 <div className="add-member-modal">
                     <div className="modal-header">
                         <button className="close-button" onClick={() => {this.props.onClose();}}>
                             <CloseOutline/>
                         </button>
                     </div>
                     <div className="modal-body">
                         <div className="modal-input-section">
                             <span>名前</span>
                             <input type="text" className="" value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}}/>
                             <span>メールアドレス</span>
                             <input type="email" className="" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
                             <span>ユーザーID</span>
                             <input type="email" className="" value={this.state.username} onChange={(e) => {this.setState({username: e.target.value})}}/>
                             <span>パスワード</span>
                             <input type="password" className="" value={this.state.password1} onChange={(e) => {this.setState({password1: e.target.value})}}/>
                             <span>パスワード(再確認)</span>
                             <input type="password" className="" value={this.state.password2} onChange={(e) => {this.setState({password2: e.target.value})}}/>
                         </div>
                         <button onClick={() => this.onCommit()}>
                             ログイン
                         </button>
                     </div>
                 </div>
             </div>
             </>
             ):(<></>)}
             </>
         )
     }
}
export default MemberRegistrationModal;